import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";

interface SearchSyntaxModalProps {
	showModal: boolean;
	closeModal: () => void;
}

export const SearchSyntaxModal = ({ showModal, closeModal }: SearchSyntaxModalProps) => {
	return (
		<Modal visible={showModal} onCancel={closeModal}>
			<TitleSecondary text="Search Filters" />
			<p>
				Try out our search filters!  Here&apos;s an overly complex way to search for Ancestral Empowerment:
			</p>
			<ul>
				<li>c=ninja</li>
				<li>s=wtr</li>
				<li>cost=0</li>
				<li>f=rf</li>
				<li>pitch=1</li>
				<li>rarity=m</li>
			</ul>
		</Modal>
	);
};
