import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import styles from "./CollectionProductSearch.module.scss";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";

export interface CollectionProductSearch {
	value: string;
	onChange: (value: string) => void;
	helpOnClick?: () => void;
}

export const CollectionProductSearch = ({ value, onChange, helpOnClick }: CollectionProductSearch): JSX.Element => {
	return (
		<div className={styles.collectionProductSearch}>
			<TextEntry
				value={value}
				onChange={onChange}
				placeholder="Search" />
			{helpOnClick &&
				<LinkButton text="Help" onClick={helpOnClick} />
			}
		</div>
	);
};
